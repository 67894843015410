const baseUrl = process.env.REACT_APP_BASEURL;
//const baseUrl = "http://localhost:4001/api/v1";
//const baseUrl = "https://k3qw61dm-4001.inc1.devtunnels.ms/api/v1";

export const createVendorUrl = `${baseUrl}/vendor/createVendor`;
export const verifyGSTIN = `${baseUrl}/verificationSuit/verifyGSTIN`;
export const verifyPAN = `${baseUrl}/verificationSuit/verifyPAN`;
export const generateOTPUrl = `${baseUrl}/verificationSuit/generateAadhaarOTP`;
export const verifyAadhaarOTPUrl = `${baseUrl}/verificationSuit/submitAadhaarOTP`;
export const verifyBankUrl = `${baseUrl}/verificationSuit/bankVerification`;

const customerBaseUrl = `${baseUrl}/user`;

const APIUrls = {
  getVendors: `${baseUrl}/vendor/getVendors`,
  getCitiesAndStates: `${baseUrl}/vendor/getCitiesAndStates`,
  getUserRole: `${baseUrl}/userRoles`,
  getEmplyeeDetails: `${baseUrl}/emp/getEmployeeDetails`,
  getVendorById: `${baseUrl}/vendor/getVendorById`,
  updateVendor: `${baseUrl}/vendor/updateVendor`,
  getAllEmployees: `${baseUrl}/emp/getAllEmployees`,
  getEmployeeById: `${baseUrl}/emp/getEmployeeById`,
  updateEmployee: `${baseUrl}/emp/updateEmp`,
  deleteEmployee: `${baseUrl}/emp/deleteEmployee`,
  getAdminDetails: `${baseUrl}/admin/getAdminDetails`,
  check_GSTIN_Exists: `${baseUrl}/vendor/check_GSTIN_Exists`,
  checkBusinessIEC_Exists: `${baseUrl}/vendor/checkBusinessIEC_Exists`,
  checkBusinessPAN_Exists: `${baseUrl}/vendor/checkBusinessPAN_Exists`,
  checkEmailExists: `${baseUrl}/vendor/checkEmailExists`,
  checkMobileNumberExists: `${baseUrl}/vendor/checkMobileNumberExists`,
  registerUser: `${baseUrl}/user/register`,
  registerUserSSO: `${baseUrl}/user/register-sso`,
  subscribeToNewsLetter: `${baseUrl}/public/subscribe`,
  fetchShops: (
    searchKey,
    categories,
    subCategories,
    genders,
    occasions,
    languages,
    page,
    limit,
    download,
    premium
  ) =>
    `${baseUrl}/vendor/fetch-shops?searchKey=${searchKey}&categories=${categories}&subCategories=${subCategories}&genders=${genders}&occasions=${occasions}&languages=${languages}&page=${page}&limit=${limit}&download=${download}&premium=${premium}`,
  fetchShopById: `${baseUrl}/vendor/fetch-shop`,
  addFavouriteShop: `${baseUrl}/user/favourite-shops`,
  fetchFavouriteShops: (searchKey, download, page, limit) =>
    `${baseUrl}/user/favourite-shops?searchKey=${searchKey}&download=${download}&page=${page}&limit=${limit}`,
  removeFavouriteShops: (id) => `${baseUrl}/user/favourite-shops/${id}`,
  fetchSlots: (id) => `${baseUrl}/vendor/${id}/slots`,
  createBooking: `${baseUrl}/user/booking`,
  forgotPassword: `${baseUrl}/user/forgot-password`,
  fetchBookings: `${baseUrl}/user/meetings`,
  fetchAllCategories: `${baseUrl}/category/categories-subcat`,
  fetchAllLanguages: `${baseUrl}/category/languages-names`,
  fetchAllOccasions: `${baseUrl}/category/occasions-names`,
  fetchPricePlans: (searchKey, download, page, limit) =>
    `${baseUrl}/plans/credits?searchKey=${searchKey}&download=${download}&page=${page}&limit=${limit}`,
  order: {
    updateOrder: (id) => `${customerBaseUrl}/orders/${id}/update-status`,
    updateShippingAddress: (id) =>
      `${customerBaseUrl}/orders/${id}/address/update`,
    fetchOrders: (searchKey, orderStatus, fromDate, toDate, download, page) =>
      `${baseUrl}/user/orders?download=${download}&searchKey=${encodeURIComponent(
        searchKey || ""
      )}&status=${encodeURIComponent(
        orderStatus || ""
      )}&fromDate=${encodeURIComponent(
        fromDate || ""
      )}&toDate=${encodeURIComponent(toDate || "")}&page=${page}`,

    trackingShipmentDetails: (id) => `${customerBaseUrl}/shipments/${id}/track`,
  },
  support: {
    fetchSupportList: (searchKey, status, fromDate, toDate, download, page) =>
      `${customerBaseUrl}/supports?searchKey=${searchKey}&status=${status}&fromDate=${fromDate}&toDate=${toDate}&download=${download}&page=${page}`,
    fetchSupportById: (id) => `${customerBaseUrl}/supports/${id}`,
    updateSupport: (id) => `${customerBaseUrl}/supports/${id}`,
    addSupport: `${customerBaseUrl}/supports`,
  },
  fetchOffers: (searchKey, offerer) =>
    `${baseUrl}/offers?searchKey=${searchKey}&offeror=${offerer}`,
  attendeeMeetJoin: (bookingId, attendeeId) =>
    `${baseUrl}/public/meet/join/${bookingId}?attendeeId=${attendeeId}`,
};
export const paymentUrl = {
  createOrder: `${baseUrl}/pay/credits/create-order`,
  verifyPayment: `${baseUrl}/pay/credits/verify`,
  paymentSuccess: `${baseUrl}/pay/credits/success`,
};
export const UserUrl = {
  fetchCredits: `${baseUrl}/user/credits`,
  fetchUsedCredits: `${baseUrl}/user/used-credits`,
  joinMeet: `${baseUrl}/user/meet/join`,
  settings: {
    updateProfile: `${baseUrl}/user/profile`,
  },
  address: {
    fetchAddresses: `${baseUrl}/user/address`,
    fetchAddressById: (id) => `${baseUrl}/user/address/${id}`,
    createAddress: `${baseUrl}/user/address`,
    deleteAddress: (id) => `${baseUrl}/user/address/${id}`,
    updateDefaultAddress: (id) => `${baseUrl}/user/address/${id}/mark-default`,
  },
};

export default APIUrls;
