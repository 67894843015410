
import { configureStore } from '@reduxjs/toolkit';
import { appManagementSlice, employeeSlice } from "./EmployeeRedux";
import { uiReduxSlice } from "./UIRedux";
import { bookingSlice } from "./userRedux";

const store = configureStore({
  reducer: {
    employee: employeeSlice.reducer,
    uiRedux: uiReduxSlice.reducer,
    booking: bookingSlice.reducer,
    appManagement: appManagementSlice.reducer,
  },
});

export const employeeActions = employeeSlice.actions;
export const uiReduxActions = uiReduxSlice.actions;
export const bookingActions = bookingSlice.actions;
export const appManagementActions = appManagementSlice.actions;

export default store;