import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  userNavbarHeight:'',
  heroBgImgHeight:'',
  hgftrCardLength:''
};

export const uiReduxSlice = createSlice({
  name: "uiRedux",
  initialState,
  reducers: {
    updateUserNavbarHeight(state, action) {
      state.userNavbarHeight = action.payload.userNavbarHeight
    },
    updateHeroBgImgHeight(state, action) {
      state.heroBgImgHeight = action.payload.heroBgImgHeight
    },
    updateHgftrCardLength(state, action) {
      state.hgftrCardLength = action.payload.hgftrCardLength
      
    },
  },
});
