/**----------------------------------Realtime database----------------------------- */
// Function to get groups for a specific user
import { get, onValue, push, ref, update } from "firebase/database";
import _ from "lodash";
import { database } from "../../Firebase";
import { PlayNotificationSound } from "../../utils/HelperFunc";

export const filterGroups = (groupsData, userId) => {
  if (groupsData) {
    // Iterate through groupMembers to find groups where the user exists
    const groupIds = Object.keys(groupsData).filter((groupId) => {
      const groupMembers = groupsData[groupId].members;
      return userId in groupMembers;
    });

    const allGroups = groupIds.map((groupId) => {
      groupsData[groupId]["groupId"] = groupId;
      return groupsData[groupId];
    });

    if (groupIds.length > 0) {
      return allGroups;
    } else {
      return [];
    }
  } else {
    return [];
  }
};

export async function getGroupsForUser(userId) {
  const groupMembersRef = ref(database, `groups`);

  try {
    // Use a Promise to wait for the callback to complete
    const groupsPromise = new Promise((resolve, reject) => {
      // Attach an onValue listener
      const unsubscribe = onValue(
        groupMembersRef,
        (snapshot) => {
          // The callback function will be invoked whenever the data changes
          const groupsData = snapshot.val();

          if (groupsData) {
            // Iterate through groupMembers to find groups where the user exists
            const groupIds = Object.keys(groupsData).filter((groupId) => {
              const groupMembers = groupsData[groupId].members;
              return userId in groupMembers;
            });

            const allGroups = groupIds.map((groupId) => {
              groupsData[groupId]["groupId"] = groupId;
              return groupsData[groupId];
            });

            if (groupIds.length > 0) {
              resolve(allGroups);
            } else {
              resolve([]);
            }
          } else {
            resolve([]);
          }
        },
        (error) => {
          // Handle errors
          console.error("Error retrieving groups:", error.message);
          reject(error.message);
        }
      );
      return () => unsubscribe();
    });

    // Wait for the Promise to resolve
    const groups = await groupsPromise;

    return groups;
  } catch (error) {
    console.error("Error:", error.message);
    return [];
  }
}

export const postMessages = async ({
  groupId,
  content,
  type,
  senderId,
  role,
}) => {
  const groupMessagesRef = ref(database, `groupMessages/${groupId}`);
  const groupMembersRef = ref(database, `groupMembers/${groupId}`);
  const groupRef = ref(database, `groups/${groupId}`);

  const timestamp = Date.now();
  const statusEvents = {};

  try {
    const groupMemberSnap = await get(groupMembersRef);
    const groupMemberData = groupMemberSnap.val();

    const groupSnap = await get(groupRef);
    const groupData = groupSnap.val();

    // Loop through group members and create entries for statusEvents
    for (const [memberId] of Object.entries(groupMemberData)) {
      if (memberId !== senderId) {
        statusEvents[memberId] = {
          status: "delivered",
          timestamp,
          events: [
            {
              status: "delivered",
              timestamp,
            },
          ],
        };

        await update(ref(database, `groups/${groupId}/usersLog/${memberId}`), {
          deliveredMessages:
            (groupData?.usersLog?.[memberId]?.deliveredMessages || 0) + 1,
        });

        const userSnap = await get(ref(database, `users/${memberId}`));
        const userData = userSnap.val();

        await update(ref(database, `users/${memberId}`), {
          deliveredMessages: (userData?.deliveredMessages || 0) + 1,
        });
      }
    }

    const message = {
      senderId,
      timestamp,
      type,
      role,
      content,
      statusEvents,
    };

    const newMessageRef = push(groupMessagesRef); // Generates a new unique key
    const newMessageKey = newMessageRef.key;

    const messageWithKey = {
      [newMessageKey]: message,
    };

    await update(groupMessagesRef, messageWithKey);
    await update(groupRef, { lastMessageId: newMessageKey });
  } catch (error) {
    console.error("Error posting message:", error.message);
  }
};

export const settingUnreadGroupsChat = async (groupsData, userId) => {
  try {
    let groups = filterGroups(groupsData, userId);

    for (const group of groups) {
      const messagesRef = ref(database, `groupMessages/${group.groupId}`); // Use `groupMessages` instead of `groupMessage`
      const messagesSnapshot = await get(messagesRef);
      const messagesData = messagesSnapshot.val() || {}; // Ensure messagesData is not null even if there are no messages

      let unreadMessageCount = 0; // Initialize unread count for each group
      let lastMessage = null;

      for (const messageId in messagesData) {
        const message = messagesData[messageId];

        if (message.senderId !== userId && message.statusEvents) {
          // Check if sender is not the user and statusEvents exists
          const userStatusEvents = message.statusEvents[userId]; // Get user's specific statusEvents
          if (userStatusEvents && userStatusEvents.status === "unseen") {
            unreadMessageCount++;

            // Update message status and add "events" property if it doesn't exist
            const updates = {
              [`groupMessages/${group.groupId}/${messageId}/statusEvents/${userId}/status`]:
                "unread",
              [`groupMessages/${group.groupId}/${messageId}/statusEvents/${userId}/timestamp`]:
                Date.now(),
            };
            if (!userStatusEvents.events) {
              userStatusEvents.events = []; // Initialize empty array if events is not present
            }

            userStatusEvents.events.push({
              status: "unread",
              timestamp: Date.now(),
            });

            updates[
              `groupMessages/${group.groupId}/${messageId}/statusEvents/${userId}/events`
            ] = userStatusEvents.events;

            await update(ref(database), updates); // Update message in database
          } else if (userStatusEvents && userStatusEvents.status === "unread") {
            unreadMessageCount++;
          }
        }
        if (!lastMessage || message.timestamp > lastMessage.timestamp) {
          lastMessage = message;
        }
      }

      // Update group with unread message count (if needed)
      group.unreadMessageCount = unreadMessageCount;
      group.lastMessage = lastMessage;

      await update(
        ref(database, `groups/${group.groupId}/usersLog/${userId}`),
        { unreadMessages: unreadMessageCount }
      );
    }
    await update(ref(database, `users/${userId}`), { unSeenMessages: 0 });

    groups = _.orderBy(
      groups,
      [(group) => _.get(group, "lastMessage.timestamp", 0)],
      ["desc"]
    );

    return groups;
  } catch (error) {
    console.error("Error fetching or updating messages:", error);
  }
};

export const settingUnseenGroupsChat = async (groupsData, userId) => {
  try {
    const groups = filterGroups(groupsData, userId);

    let totalUnseenMessageCounts = 0;

    for (const group of groups) {
      const messagesRef = ref(database, `groupMessages/${group.groupId}`); // Use `groupMessages` instead of `groupMessage`
      const messagesSnapshot = await get(messagesRef);
      const messagesData = messagesSnapshot.val() || {}; // Ensure messagesData is not null even if there are no messages

      for (const messageId in messagesData) {
        const message = messagesData[messageId];

        if (message.senderId !== userId && message.statusEvents) {
          // Check if sender is not the user and statusEvents exists
          const userStatusEvents = message.statusEvents[userId]; // Get user's specific statusEvents
          if (userStatusEvents && userStatusEvents.status === "delivered") {
            totalUnseenMessageCounts++;
            PlayNotificationSound();

            // Update message status and add "events" property if it doesn't exist
            const updates = {
              [`groupMessages/${group.groupId}/${messageId}/statusEvents/${userId}/status`]:
                "unseen",
              [`groupMessages/${group.groupId}/${messageId}/statusEvents/${userId}/timestamp`]:
                Date.now(),
            };
            if (!userStatusEvents.events) {
              userStatusEvents.events = []; // Initialize empty array if events is not present
            }

            userStatusEvents.events.push({
              status: "unseen",
              timestamp: Date.now(),
            });

            updates[
              `groupMessages/${group.groupId}/${messageId}/statusEvents/${userId}/events`
            ] = userStatusEvents.events;

            await update(ref(database), updates); // Update message in database
          } else if (userStatusEvents && userStatusEvents.status === "unseen") {
            totalUnseenMessageCounts++;
          }
        }
      }
    }
    await update(ref(database, `users/${userId}`), {
      unSeenMessages: totalUnseenMessageCounts,
    });
    return totalUnseenMessageCounts;
  } catch (error) {
    console.error("Error fetching or updating messages:", error);
  }
};

export const updateMessage = async ({ msgId, groupId, updateData }) => {
  const groupMessagesRef = ref(
    database, // Make sure you have 'database' defined somewhere in your code
    `groupMessages/${groupId}/${msgId}/content/props/data`
  );

  try {
    await update(groupMessagesRef, updateData);
  } catch (error) {
    console.error("Error updating message:", error.message);
  }
};
