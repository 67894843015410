import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fullName: null,
  email: null,
  meetingSlot: null,
  creditsUsed: 30,
  invitees: [],
  categories: [],
  formSaveLoaderBtn: false,
  disableBtn: true,
  priceRange: {
    min: null,
    max: null,
    currency: "INR",
  },
};

export const bookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    updateDetails(state, action) {
      // Spread the existing state and then apply the payload
      return { ...state, ...action.payload };
    },
    reset(state, action) {
      return initialState;
    },
  },
});
