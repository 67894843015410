import React, { Suspense } from "react";
import {
  createBrowserRouter,
  Navigate
} from "react-router-dom";
import EjoyshopLoader from "../components/loader/EjoyshopLoader";
import { useAuth } from "../stores/AuthContext";

const UserPanelLayout = React.lazy(() => import("../layouts/UserPanelLayout"));
const HomePage = React.lazy(() => import("../pages/HomePage"));
const PricingPage = React.lazy(() => import("../pages/PricingPage"));
const HowItWorksPage = React.lazy(() => import("../pages/HowItWorksPage"));
const BrandsPage = React.lazy(() => import("../pages/BrandsPage"));
const ExploreShopPage = React.lazy(() => import("../pages/ExploreShopPage"));
const BookMeeting = React.lazy(() =>
  import("../components/exploreShops/BookMeeting")
);
const Wrapper = React.lazy(() => import("../components/exploreShops/Wrapper"));
const Meeting = React.lazy(() => import("../components/userpanel/Meeting"));
const Chat = React.lazy(() => import("../components/userpanel/Chat"));
const Credits = React.lazy(() => import("../components/userpanel/Credits"));
const Favourites = React.lazy(() =>
  import("../components/userpanel/Favourites")
);
const Orders = React.lazy(() => import("../components/userpanel/Orders"));
const InviteFriends = React.lazy(() =>
  import("../components/userpanel/InviteFriends")
);
const Support = React.lazy(() => import("../components/userpanel/Support"));

const OfferPage = React.lazy(() => import("../pages/OfferPage"));
const PaymentSuccess = React.lazy(() => import("../pages/PaymentSuccess"));
const Address = React.lazy(() => import("../components/userpanel/Address"));


const Settings = React.lazy(() =>
  import("../components/userpanel/settingsModules/ProfileSettings")
);

const ProfileSettings = React.lazy(() =>
  import("../components/userpanel/settingsModules/ProfileSettings")
);

const ShipmentTrack = React.lazy(() =>
  import("../components/userpanel/ShipmentTrack")
);
const OrdersList = React.lazy(() =>
  import("../components/userpanel/OrderList")
);

const AddSupport = React.lazy(() =>
  import("../components/userpanel/supportModules/supportModules/AddSupport")
);
const EditSupport = React.lazy(() =>
  import("../components/userpanel/supportModules/supportModules/EditSupport")
);

const PageNotFound = React.lazy(() => import("../utils/PageNotFound"));
const JoinMeet = React.lazy(() => import("../pages/JoinMeet"));

const TermsAndCondition = React.lazy(() =>
  import("../pages/TermsAndCondition")
);
const RefundPolicy = React.lazy(() => import("../pages/RefundPolicy"));
const PrivacyPolicy = React.lazy(() => import("../pages/PrivacyPolicy"));
const ShippingAndReturnPolicy = React.lazy(() =>
  import("../pages/ShippingAndReturnPolicy")
);

const UserLandingPage = React.lazy(() => import("../pages/UserLandingPage"));
const VendorLandingPage = React.lazy(() =>
  import("../pages/VendorLandingPage")
);
const Root = React.lazy(() => import("./Root"));
const UserRoot = React.lazy(() => import("./UserRoot"));
const UserLogin = React.lazy(() => import("../pages/login/UserLogin"));
const UserForgotPassord = React.lazy(() =>
  import("../pages/login/UserForgotPassord")
);
const UserLoginCard = React.lazy(() => import("../pages/login/UserLoginCard"));
const UserRegisterCard = React.lazy(() =>
  import("../pages/login/UserRegisterCard")
);

const PrivateRoute = ({ component: Component }) => {
  const { currentUser, userRole } = useAuth();

  return (
    <>
      {currentUser && userRole?.customer ? (
        <Suspense fallback={<EjoyshopLoader />}>
          <Component />
        </Suspense>
      ) : (
        <Navigate to='/auth/login' replace />
      )}
    </>
  );
};

export const router = createBrowserRouter([
  {
    path: "/vendor",
    element: (
      <Suspense fallback={<EjoyshopLoader />}>
        <Root />
      </Suspense>
    ),
    children: [
      {
        path: "",
        element: (
          <Suspense fallback={<EjoyshopLoader />}>
            <UserLandingPage />
          </Suspense>
        ),
      },
      {
        path: "vendorLanding",
        element: (
          <Suspense fallback={<EjoyshopLoader />}>
            <VendorLandingPage />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "/auth",
    element: (
      <Suspense fallback={<EjoyshopLoader />}>
        <UserLogin />
      </Suspense>
    ),
    children: [
      {
        path: "",
        element: (
          <Suspense fallback={<EjoyshopLoader />}>
            <UserRegisterCard />
          </Suspense>
        ),
      },
      {
        path: "login",
        element: (
          <Suspense fallback={<EjoyshopLoader />}>
            <UserLoginCard />
          </Suspense>
        ),
      },
      {
        path: "forgot-password",
        element: (
          <Suspense fallback={<EjoyshopLoader />}>
            <UserForgotPassord />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "/",
    element: (
      <Suspense fallback={<EjoyshopLoader />}>
        <UserRoot />
      </Suspense>
    ),
    children: [
      {
        path: "",
        element: (
          <Suspense fallback={<EjoyshopLoader />}>
            <HomePage />
          </Suspense>
        ),
      },
      {
        path: "pricing",
        element: (
          <Suspense fallback={<EjoyshopLoader />}>
            <PricingPage />
          </Suspense>
        ),
      },
      {
        path: "demo",
        element: (
          <Suspense fallback={<EjoyshopLoader />}>
            <HowItWorksPage />
          </Suspense>
        ),
      },
      {
        path: "brands",
        element: (
          <Suspense fallback={<EjoyshopLoader />}>
            <BrandsPage />
          </Suspense>
        ),
      },
      {
        path: "offers",
        element: (
          <Suspense fallback={<EjoyshopLoader />}>
            <OfferPage />
          </Suspense>
        ),
      },
      {
        path: "shop/:id",
        element: (
          <Suspense fallback={<EjoyshopLoader />}>
            <Wrapper />
          </Suspense>
        ),
        children: [
          {
            path: "",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                <ExploreShopPage />
              </Suspense>
            ),
          },
          {
            path: "book-meeting",
            element: <PrivateRoute component={BookMeeting} />,
          },
        ],
      },
      {
        path: "/terms-and-conditions",
        element: (
          <Suspense fallback={<EjoyshopLoader />}>
            <TermsAndCondition />
          </Suspense>
        ),
      },
      {
        path: "/refund-policy",
        element: (
          <Suspense fallback={<EjoyshopLoader />}>
            <RefundPolicy />
          </Suspense>
        ),
      },
      {
        path: "/privacy-policy",
        element: (
          <Suspense fallback={<EjoyshopLoader />}>
            <PrivacyPolicy />
          </Suspense>
        ),
      },
      {
        path: "/shipping-and-return-policy",
        element: (
          <Suspense fallback={<EjoyshopLoader />}>
            <ShippingAndReturnPolicy />
          </Suspense>
        ),
      },
      {
        path: "user",
        element: (
          <Suspense fallback={<EjoyshopLoader />}>
            <UserPanelLayout />
          </Suspense>
        ),
        children: [
          {
            path: "",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                <PrivateRoute component={Meeting} />
              </Suspense>
            ),
          },
          {
            path: "chat",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                <PrivateRoute component={Chat} />
              </Suspense>
            ),
          },
          {
            path: "credits",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                <PrivateRoute component={Credits} />
              </Suspense>
            ),
          },
          {
            path: "favourites",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                <PrivateRoute component={Favourites} />
              </Suspense>
            ),
          },
          {
            path: "orders",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                <PrivateRoute component={Orders} />
              </Suspense>
            ),
            children: [
              {
                path: "",
                element: (
                  <Suspense fallback={<EjoyshopLoader />}>
                    <PrivateRoute component={OrdersList} />
                  </Suspense>
                ),
              },
              {
                path: ":id",
                element: (
                  <Suspense fallback={<EjoyshopLoader />}>
                    <PrivateRoute component={ShipmentTrack} />
                  </Suspense>
                ),
              },
            ],
          },

          {
            path: "address",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                <PrivateRoute component={Address} />
              </Suspense>
            ),
          },
          {
            path: "invite-friends",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                <PrivateRoute component={InviteFriends} />
              </Suspense>
            ),
          },
          {
            path: "support",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                <PrivateRoute component={Support} />
              </Suspense>
            ),
            children: [
              {
                path: "",
                element: (
                  <Suspense fallback={<EjoyshopLoader />}>
                    <PrivateRoute component={AddSupport} />
                  </Suspense>
                ),
              },
              {
                path: "edit/:id",
                element: (
                  <Suspense fallback={<EjoyshopLoader />}>
                    <PrivateRoute component={EditSupport} />
                  </Suspense>
                ),
              },
            ],
          },
      
          {
            path: "settings",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                <PrivateRoute component={Settings} />
              </Suspense>
            ),
            children: [
              {
                path: "",
                element: (
                  <Suspense fallback={<EjoyshopLoader />}>
                    <PrivateRoute component={ProfileSettings} />
                  </Suspense>
                ),
              },
           
            ],
          },
        ],
      },
      {
        path: "success",
        element: <PrivateRoute component={PaymentSuccess} />,
      },
    ],
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
  {
    path: "join-meet/:bookingId/:attendeeId",
    element: (
      <Suspense fallback={<EjoyshopLoader />}>
        <JoinMeet />
      </Suspense>
    ),
  },
]);
