import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  employeeDetails: null,
  formSaveLoaderBtn: false,
  userName: null,
  userId: null,
  offerSearchKey: "",
};

export const employeeSlice = createSlice({
  name: "employee",
  initialState,
  reducers: {
    updateEmployeeDetails(state, action) {
      state.employeeDetails = action.payload.employeeDetails;
    },
    updateUserName(state, action) {
      state.userName = action.payload.userName;
    },
    updateUserId(state, action) {
      state.userId = action.payload.userId;
    },

    updateOfferSearchKey(state, action) {
      state.offerSearchKey = action.payload;
    },
  },
});

const appManagementState = {
  notifications: {
    personalized: [],
    userGeneral: [],
    general: [],
    unreadPersonlizedCounts: 0,
    unreadUserGeneralCounts: 0,
    unreadGeneralCounts: 0,
    newNotification: false,
  },
};

export const appManagementSlice = createSlice({
  name: "appManagement",
  initialState: appManagementState,
  reducers: {
    updateNotifications(state, action) {
      state.notifications = {
        ...state.notifications,
        ...action.payload,
      };
    },
  },
});
