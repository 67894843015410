import { useSelector } from "react-redux";
import "./style.css";

const EjoyshopLoader = () => {
  const { userNavbarHeight } = useSelector((state) => state.uiRedux);
  return (
    <div
      //style={{ height: `calc(100vh - ${userNavbarHeight}px)` }}
      className='h-screen w-full flex justify-center items-center bg-white'>
      <img
        src={
          "https://res.cloudinary.com/dhsfan19o/image/upload/v1718446510/EJOYSHOP-USER/ejoyshopLogo_hxsuap.jpg"
        }
        className='logo-image'
      />
    </div>
  );
};

export default EjoyshopLoader;
